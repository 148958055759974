@font-face {
  font-family: 'Union-Nataal';
  src: local('Union-Nataal'),
       url('/public/fonts/Union-Nataal.woff2') format('woff2'),
       url('/public/fonts/Union-Nataal.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

body, html {
  background: #4D4C4E;
  margin: 0;
  padding: 0;
  width: 100%;
  height: -webkit-fill-available; /* occupy full height */
}

.App {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100); /* Use the custom viewport height */
}

.header,
.footer {
  flex-shrink: 0; /* prevent flex items from shrinking */
}

.image-container {
  background: #4D4C4E;
  flex-grow: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100); /* Use the custom viewport height */
  width: 100vw;
  overflow: hidden;
}

.image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

@media screen and (max-width: 1024px) {
 .image {
    max-width: 95%;
    max-height: 95%;
 } 
}

.image img {
  display: block;
}