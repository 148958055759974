.footer {
    background: #4D4C4E;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
}

.links {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

.link {
    font-size: 12px;
    text-transform: uppercase;
    color: black;
    letter-spacing: -0.1px;
    font-family: 'Union-Nataal';
}

.link a {
    color: black;
    text-decoration: none;
}

.link a:active, .link a:visited {
    color: black;
    text-decoration: none;
}

.link a:hover {
    opacity: 0.5;
}