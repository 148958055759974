.header {
    background: #4D4C4E;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
}

.logo {
    max-height: 48px;
}